import React from "react";
import Logo1 from "../../icons/Logo1/Logo1";
import Login from "../../components/login/Login";
import "./global.css"
import "./styleguide.css"
import "./style.css";

const Desktop: React.FC = () => {
  const [show, setShow] = React.useState(false);
  
  const handleLoginClick = (data: boolean) => {
    console.log(data)
    setShow(data);
  };

  const handleClick = (e:any) => {
    e.preventDefault();
    e.getModifierState("Shift")
    e.stopPropagation(); 
    console.log(e)
    setShow(true);
  };

  return (
    <div className="desktop" data-testid="landing-page-main">
      <img
        className="light bgimage"
        alt="Light"
        src="/img/u5391529864-light-grey-geometric-mesh-overlaying-a-dark-blue-00.png"
      />
      <header className="header">
        <Logo1 className="logo" />
        {/* <div className="menu">
          <div className="text-wrapper">Features</div>
          <div className="text-wrapper">Pricing</div>
          <div className="text-wrapper">Support</div>
        </div> */}
        <div className="buttons">
          <button className="button" onClick={(e:any)=>handleClick(e)}>
            <div className="div">Login</div>
            <img className="arrow" alt="Arrow" src="/img/arrow.svg" />
          </button>
        </div>
      </header>
      <img className="hero-section" alt="Hero section" src="/img/hero-section.png" />
      <div className="testimonial">
        <div className="testimonial-2">
          <img className="image" alt="Testimonial author" src="/img/image.svg" />
          <p className="OMG-i-cannot-belie">
            The real-time synchronization and AI-powered organization have made our workflow more efficient than ever.
          </p>
          <div className="author">
            <div className="text-wrapper"> Sarah Johnson</div>
            <div className="graphic-designer">TechSavvy Solutions</div>
          </div>
        </div>
      </div>
      <div className="feature">
        <div className="heading">
          <img className="discover-the-power" alt="Discover the power" src="/img/discover-the-power-of-epicenter.png" />
          <p className="p">
            Epicenter is packed with innovative features designed to revolutionize the way you take notes, collaborate
            with others, and stay organized.
          </p>
        </div>
        <div className="features">
          <div className="overlap">
            <div className="overlap-group">
              <img className="layer" alt="Layer" src="/img/layer-1.svg" />
              <div className="feature-2">
                <img className="img" alt="Layer" src="/img/layer-1-1.svg" />
                <div className="content">
                  <div className="text-wrapper-2">Real-Time Collaboration</div>
                  <p className="text-wrapper-3">
                    Collaborate seamlessly with your team members or classmates in real-time on shared notes and
                    projects.
                  </p>
                </div>
              </div>
              <div className="feature-3">
                <img className="img" alt="Hashtag" src="/img/hashtag.svg" />
                <div className="content-2">
                  <div className="text-wrapper-2">AI-Powered Organization</div>
                  <p className="text-wrapper-3">
                    Our AI-powered organization feature categorizes and prioritizes your notes, making it easy to find
                    what you need when you need it.
                  </p>
                </div>
              </div>
              <div className="feature-4">
                <img className="img" alt="Global fill" src="/img/global-fill.svg" />
                <div className="content">
                  <div className="text-wrapper-2">AI-Generated Code</div>
                  <p className="text-wrapper-3">
                    Prompt-driven: Collaborate with our agent, ideate together, and let the AI do the heavy lifting.
                    Apply industry best practices with a single command.
                  </p>
                </div>
              </div>
              <div className="feature-5">
                <img className="img" alt="Flag fill" src="/img/flag-2-fill.svg" />
                <div className="content">
                  <div className="text-wrapper-2">Unit Test-Driven Development</div>
                  <p className="text-wrapper-3">
                    What&#39;s built is driven from tests, so that you know that it works, that it&#39;s reliable, and
                    that you can change it without breaking it.
                  </p>
                </div>
              </div>
              <div className="feature-6">
                <img className="img" alt="Thumb up fill" src="/img/thumb-up-fill.svg" />
                <div className="content">
                  <div className="text-wrapper-2">Intelligent Command Line</div>
                  <p className="text-wrapper-3">
                    Work where you want to work: Whether on the web or on the comandline, generate, test, commit and
                    deploy code from the command line.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-7">
              <img className="img" alt="Git branch fill" src="/img/git-branch-fill.svg" />
              <div className="content">
                <div className="text-wrapper-2">Integrations</div>
                <p className="text-wrapper-3">
                  Connect Epicenter with your favorite productivity tools like Google Drive, Trello, and Slack.
                  Streamline your workflow by syncing data across platforms and enhancing collaboration.c
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CTA">
        <div className="CTA-container">
          {/* <div className="container">
            <div className="content-3">
              <img
                className="unlock-the-full"
                alt="Unlock the full"
                src="/img/unlock-the-full-potential-of-epicenter.png"
              />
              <p className="text-wrapper-3">
                Ready to streamline your workflow and boost your productivity? Start your free trial of Epicenter today
                and experience the power of smart development.
              </p>
            </div>
            <div className="group">
              <div className="frame">
                <div className="input-feild">
                  <div className="text">Name</div>
                </div>
                <div className="input-feild">
                  <div className="text">Email</div>
                </div>
                <div className="input-feild">
                  <div className="text">Phone</div>
                </div>
                <div className="input-feild-2">
                  <div className="text">Message</div>
                </div>
                <button className="primary-BTN" title="send">
                  <button className="button-text">Send</button>
                </button>
              </div>
            </div>
          </div> */}
          {/* <div className="CTA-shapes">
            <img className="img-2" alt="Cta shapes" src="/img/cta-shapes-1.svg" />
            <img className="img-2" alt="Frame" src="/img/frame-1.svg" />
            <img className="img-2" alt="Cta shapes" src="/img/cta-shapes-2.svg" />
            <div className="overlay" />
          </div> */}
        </div>
        <img className="layer-2" alt="Layer" src="/img/layer-1-2.svg" />
      </div>
      <footer className="footer">
        <div className="container-2">
          <div className="sub-link-container">
            <p className="link">@2024 Epicenter. All Rights Reserved.</p>
          </div>
          <img className="img-2" alt="Social icon" src="/img/social-icon.svg" />
        </div>
      </footer>
      {show && <Login handleLoginClick={handleLoginClick} setShow={setShow} />}
      </div>
  );
};

export default Desktop;