import React from 'react';
import './ChatBox.css';

interface ConnectionStateProps {
  isConnected: boolean;
}

const ConnectionState: React.FC<ConnectionStateProps> = ({ isConnected }) => {

  return (
    <div className="connection_state">
      <span className = {`dot ${isConnected ? 'connected' : 'disconnected'}`}></span>
      <span>{isConnected ? 'Connected' : 'Disconnected'}</span>
    </div>
  );
};

export default ConnectionState;