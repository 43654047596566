import React, { useState, useEffect } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { MessageData } from './Message';
import './ChatBox.css';
import { useApiKey } from "../../generic components/ApiKeyContext";
import ConnectionState from './ConnectionState';
import socket from './socketio';
import { v4 as uuidv4 } from 'uuid';

const SOCKET_TIMEOUT = 5000;

const ChatBox: React.FC = () => {
  const [messages, setMessages] = useState<MessageData[]>([]);
  const [input, setInput] = useState('');
  const { api_key } = useApiKey();
  const [isConnected, setIsConnected] = useState<boolean>(socket.connected);
  const [session_id, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    function login(api_key: string) {
        if (api_key) {
            socket.emit('login', { api_key });
            console.debug('logging in with api key', api_key.substring(0, 5) + '...');
        }
        else {
            console.error('api key not found');
        }
    }

    function onConnect() {
      console.log('Handling onConnect()');
      setIsConnected(true);
      login(api_key);
    }

    function onLoginSuccess(data: any) {
        console.debug('login success:', data);
      setSessionId(data.session_id);
    }

    function onDisconnect() {
      cleanUpSession();
    }

    function cleanUpSession() {
        console.debug('Cleaning up session...');
        setIsConnected(false);
        setSessionId(null);
    }

    function onStatusEvent(data: any) {
      console.log('status event:', data);
      if (data.status === 'OK') {
        setIsConnected(true);
      } else {
        cleanUpSession();
      }
    }
    function onMessageEvent(value: any) {
        if (value && typeof value.id === 'string' && typeof value.message === 'string' && typeof value.sender === 'string') {
        // function onMessageEvent(value: { id: string; message: string; sender: string, type: string }) {
            const msg: MessageData = {
                id: value.id,
                text: value.message,
                sender: value.sender!
            };
            setMessages((prevMessages) => [...prevMessages, msg]);
            console.debug('Received message:', msg);
        } else {
            console.error('Invalid message received:', value);
        } 
    //   setLastMessage(msg);
    }


    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('login_success', onLoginSuccess);
    socket.on('message', onMessageEvent);
    socket.on('status', onStatusEvent);
    // Attempt to connect (if not connected already)
    if (!socket.connected) {
      socket.connect();
    }

    async function sendHeartbeat(): Promise<boolean> {
        if (!socket.connected) {
            console.warn("WebSocket is not connected, can't send heartbeat.");
            return false; // Return false if WebSocket is not connected
        }

        if (! session_id) {
            console.warn("No valid session ID.");
            return false; // Return false if session ID is not set
        }
    
        return new Promise<boolean>((resolve) => {
            console.debug("Sending heartbeat...");
    
            // Start the timeout immediately
            const timeout = setTimeout(() => {
                console.debug('Heartbeat response timed out');
                resolve(false); // Timeout case
            }, 5000); // Adjust timeout as necessary
    
            // Emit the heartbeat and handle acknowledgment
            socket.emit('status', { message: "OK" }, (ack: { status: string }) => {
                if (ack && ack.status === 'OK') {
                    console.debug('Heartbeat acknowledged by server.');
                    clearTimeout(timeout); // Clear the timeout on success
                    resolve(true); // Successful acknowledgment
                } else {
                    console.debug('Heartbeat failed to get acknowledgment.');
                    clearTimeout(timeout); // Clear the timeout if the acknowledgment fails
                    resolve(false); // Failed acknowledgment
                }
            });
        });
    }        
     
    const heartbeatInterval = setInterval(async () => {
        const isSuccessful = await sendHeartbeat(); // Wait for heartbeat result
        setIsConnected(isSuccessful); // Update connection state based on the result
        // if (!isSuccessful) {
        //     cleanUpSession(); // Clean up the session if the heartbeat fails
        // }
    }, SOCKET_TIMEOUT);

    return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('login_success', onLoginSuccess);
        socket.off('message', onMessageEvent);
        socket.off('status', onStatusEvent);
clearInterval(heartbeatInterval);
};
  
}, [api_key, session_id]);
   
  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessage: MessageData = { id: uuidv4(), text: input, sender: 'You' };
      if (session_id) {
          socket?.emit('message', { session_id: session_id, id: newMessage.id, message: input, role: 'user' });
          //socket?.send(JSON.stringify({ event: 'ask', question: input, role: 'user' }));
      }
      setMessages([...messages, newMessage]);
      setInput('');
    }
  };

  return (
    <div className="chat-box-container">
      <ConnectionState isConnected={isConnected} />
      {/* <div className="connection_state">session id: {session_id}</div> */}
      <div className="message-list-container">
        <MessageList messages={messages} />
      </div>
      <div className="message-input-container">
        <MessageInput input={input} onInputChange={setInput} onSendMessage={handleSendMessage} />
      </div>
    </div>
  );
};

export default ChatBox;
