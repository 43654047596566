import { BASE_URL } from "../../config/config";
import { io } from 'socket.io-client';

// note this doens't need a ws prefix, can
// just use the BASE_URL
const socket = io(BASE_URL + '/ws', {
  transports: ['websocket']
});

export default socket;
