import React from 'react';
import './ChatBox.css';

export interface MessageData {
  id: string;
  text: string;
  sender: string;
}

interface MessageProps {
  message: MessageData;
}

const Message: React.FC<MessageProps> = ({ message }) => (
  <div className={`message ${message.sender === 'You' ? 'sent' : 'received'}`}>
    <div className="avatar-bubble">
      <strong>{message.sender}</strong>
    </div>
    <div className="message-bubble">{message.text}</div>
  </div>
);

export default Message;
